import { Options, Vue } from "vue-class-component";
import store from "./store";


export default class App extends Vue {

  async created() {
    window.addEventListener("resize", this.setStoreViewport);
    this.setStoreViewport();
  }

  private setStoreViewport() {
    const viewport = {
      height: document.body.clientHeight,
      width: document.body.clientWidth
    };
    store.state.viewport = viewport;
    if (viewport.width > 900) {
      store.state.isMobile = false;
    }
    else {
      store.state.isMobile = true;
    }
  }

}


