import { createStore } from 'vuex'

export default createStore({
  state: {
    viewport: {height: 0, width: 0},
    isMobile: true,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
